import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let clientsSaySwiperWr = document.querySelectorAll('.global-swiper-wr');
clientsSaySwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Navigation],
      observer: true,
      observeParents: true,
      spaceBetween: 20,
      slidesPerView: 1,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        640: {
          spaceBetween: 16,
          slidesPerView: 1,
        },
        768: {
          spaceBetween: 20,
          slidesPerView: 1.5,
        },
        1024: {
          spaceBetween: 16,
          slidesPerView: 2,
        },
        1200: {
          spaceBetween: 20,
          slidesPerView: 2.66,
        },
      },
    });

    swiperObserver(swiper);
  }
});

if (document.querySelector('.global-section__item')) {
  const globalItemsArr = document.querySelectorAll('.global-section__item');
  globalItemsArr.forEach((item) => {
    item.addEventListener('click', function () {
      if (!item.classList.contains('hovered')) {
        for (let i = 0; i < globalItemsArr.length; i++) {
          globalItemsArr[i].classList.remove('hovered');
        }

        item.classList.add('hovered');
      } else {
        item.classList.remove('hovered');
      }
    });
  });
}
