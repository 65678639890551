import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let hireSwiperWr = document.querySelectorAll('.hire-swiper-wr');
hireSwiperWr.forEach((el) => {
    if (el) {
        let swiperEl = el.querySelector('.swiper');
        let nextEl = el.querySelector('.swiper-button-next');
        let prevEl = el.querySelector('.swiper-button-prev');
        let slidesCount = el.querySelectorAll('.swiper-slide').length;
        let wrapper = swiperEl.querySelector('.swiper-wrapper');

        if (nextEl) {
            console.log("nextel")
        }

        let swiper = new Swiper(swiperEl, {
            modules: [Autoplay, Navigation],
            observer: true,
            observeParents: true,
            spaceBetween: 20,
            slidesPerView: 2,
            threshold: 10,
            navigation: {
                nextEl: nextEl,
                prevEl: prevEl,
            },
            breakpoints: {
                375: {
                    spaceBetween: 20,
                    slidesPerView: 1,
                },
                768: {
                    spaceBetween: 20,
                    slidesPerView: 2,
                },
                1024: {
                    spaceBetween: 16,
                    slidesPerView: 2,
                },
                1200: {
                    spaceBetween: 20,
                    slidesPerView: 2,
                },
            },
        });

        swiperObserver(swiper);
    }
});