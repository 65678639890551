import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let jobsSwiperWr = document.querySelectorAll('.jobs-swiper-wr');
jobsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 20,
      slidesPerView: 1,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        768: {
          spaceBetween: 20,
          slidesPerView: 2,
        },
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      on: {
        afterInit: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },

        resize: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },
      },
    });
    swiperObserver(swiper);
  }
});

function setBottomHeight() {
  if (document.getElementsByClassName('job-card')[0]) {
    const jobsCardsArr = document.querySelectorAll('.job-card');

    jobsCardsArr.forEach((card) => {
      const bottomEl = card.querySelector('.job-card__bottom');
      if(bottomEl) {
        const bottomElHeight = bottomEl?.clientHeight - 5;

        card.style.setProperty('--bottom-height', `${bottomElHeight}px`);
      }
    });
  }
}

window.addEventListener('load', setBottomHeight);
window.addEventListener('resize', setBottomHeight);
